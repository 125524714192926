import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NewsletterSubscriptionContainer as SourceNewsletterSubscriptionContainer,
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.container';
import { RootState } from 'Util/Store/Store.type';

/** @namespace Pwa/Component/NewsletterSubscription/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/NewsletterSubscription/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/NewsletterSubscription/Container */
export class NewsletterSubscriptionContainer extends SourceNewsletterSubscriptionContainer {
    // TODO implement logic
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewsletterSubscriptionContainer);
