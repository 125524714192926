/* eslint-disable max-len */
import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

/** @namespace Pwa/Component/Visa/Component */
export class VisaComponent extends PureComponent {
    render(): ReactElement {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" id="visa" height="40">
                <g fill="#000">
                    <path d="m22.504 13.84-.895 2.138h1.4l-.39-1.646z" />
                    <path d="M28 4H2C.897 4 0 4.897 0 6v18c0 1.103.897 2 2 2h26c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM5.006 12.43a.185.185 0 0 1 .185-.143l1.768.002h.007c.259.01.616.068.729.459l.002.009.314 1.368a.17.17 0 0 1-.106.194.2.2 0 0 1-.228-.053c-.59-.717-1.472-1.285-2.55-1.643a.172.172 0 0 1-.121-.193zm4.163 5.562-1.517.002a.187.187 0 0 1-.18-.124l-1.318-4.154c-.023-.072.007-.15.074-.192s.156-.04.22.006c.95.673 1.63 1.52 1.882 2.062a.142.142 0 0 1 .009.023l.172.575 1.603-3.777a.188.188 0 0 1 .175-.11l1.37-.001a.19.19 0 0 1 .157.078c.035.048.041.11.016.164l-2.49 5.344a.19.19 0 0 1-.173.104zm4.115-.138a.184.184 0 0 1-.186.145h-1.302a.195.195 0 0 1-.144-.062.165.165 0 0 1-.042-.141l.99-5.355a.184.184 0 0 1 .185-.144h1.303c.055 0 .108.023.144.062.035.039.05.09.041.14l-.99 5.355zm4.395-3.245c.933.39 1.366.863 1.36 1.49-.012 1.141-1.122 1.879-2.827 1.88h-.002c-.595-.007-1.215-.096-1.659-.24a.173.173 0 0 1-.123-.193l.161-.87a.175.175 0 0 1 .095-.123.202.202 0 0 1 .163-.008c.504.193.86.285 1.522.285.464 0 .966-.167.97-.536.003-.241-.209-.413-.84-.682-.616-.263-1.434-.704-1.423-1.494.009-1.068 1.137-1.814 2.744-1.814.567 0 1.02.099 1.3.182.09.026.144.109.128.194l-.152.826a.175.175 0 0 1-.093.122.202.202 0 0 1-.16.011 3.176 3.176 0 0 0-1.147-.187c-.614 0-.934.237-.934.471-.003.26.347.43.917.686zm7.282 3.327a.194.194 0 0 1-.146.065h-1.177a.185.185 0 0 1-.184-.137c-.04-.165-.134-.566-.173-.717l-1.183-.001h-.025l-.949-.001c-.035.083-.142.34-.307.744a.189.189 0 0 1-.176.112h-1.35a.192.192 0 0 1-.158-.079.162.162 0 0 1-.014-.165l2.383-4.981c.159-.332.423-.473.883-.473h1.15c.088 0 .164.056.183.136l1.28 5.35a.164.164 0 0 1-.037.147z" />
                </g>
            </svg>
        );
    }
}

export default VisaComponent;
