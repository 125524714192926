/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { FieldContainerProps } from '@scandipwa/scandipwa/src/component/Field/Field.type';
import Image from '@scandipwa/scandipwa/src/component/Image';

import { FieldType } from 'Component/Field/Field.config';
import {
    NewsletterSubscriptionComponent as SourceNewsletterSubscriptionComponent,
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';
import { ReactElement } from 'Type/Common.type';

import background from './assets/newsletter_box.png';
import newsletterSubscriptionForm from './NewsletterForm.form';

import './NewsletterSubscription.override.style';

/** @namespace Pwa/Component/NewsletterSubscription/Component */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscriptionComponent {
    fieldMap(): Partial<FieldContainerProps>[] {
        return newsletterSubscriptionForm();
    }

    renderActions(): ReactElement {
        return (
            <button
              type={ FieldType.SUBMIT }
              block="Button submit"
              mods={ { isHollow: true } }
              aria-label={ __('Submit') }
            >
                { __('Subscribe') }
            </button>
        );
    }

    renderFormBody(): ReactElement {
        return (
            <>
            <Image src={ background } alt="" isPlain />
                <div block="FieldContainer">
                    <div>
                        <p block="newsletter_subtitle">{ __('MOTORPARTS') }</p>
                        <p block="newsletter_title">{ __('Iscriviti alla Newsletter') }</p>
                        <p block="newsletter_desc">{ __('Resta aggiornato su novità e prodotti') }</p>
                    </div>
                    <div block="FieldForm" elem="Body">
                        <div block="FieldForm" elem="InputField">
                            { (this.renderSection(this.fieldMap()[0])) }
                        </div>
                        <div>
                        { (this.renderSection(this.fieldMap()[1])) }
                        </div>
                        { this.renderActions() }
                    </div>
                </div>
            </>
        );
    }

    render(): ReactElement {
        return (
            <div block="NewsletterSubscription Container">
                { super.render() }
            </div>
        );
    }
}

export default NewsletterSubscriptionComponent;
