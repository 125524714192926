/* eslint-disable max-len */
import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

/** @namespace Pwa/Component/PayPal/Component */
export class PayPalComponent extends PureComponent {
    render(): ReactElement {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" id="cc-paypal" height="40">
                <path d="M18.916 11.45L18.916 11.45c-.508.001-.904.402-.904.909 0 .401.292.619.675.619.5 0 .904-.38.904-.888C19.595 11.686 19.304 11.45 18.916 11.45zM15.188 10.089L15.188 10.089 15 10.088c-.075 0-.125.041-.133.112l-.175 1.103.333-.012c.541 0 .916-.124.916-.744C15.937 10.109 15.541 10.089 15.188 10.089zM3.354 10.089L3.354 10.089l-.196-.001c-.062 0-.125.041-.133.112l-.18 1.103.341-.012c.459 0 .812-.062.896-.587C4.179 10.151 3.824 10.089 3.354 10.089zM7.084 11.451c-.508 0-.904.404-.904.908 0 .404.284.619.667.619.512 0 .916-.384.916-.888C7.762 11.686 7.471 11.451 7.084 11.451z" />
                <path d="M24,19.268V4.732c0-1.094-0.896-1.982-2-1.982H2c-1.104,0-2,0.888-2,1.982v14.536c0,1.094,0.896,1.982,2,1.982h20C23.104,21.25,24,20.362,24,19.268z M3.425,12.215c-0.2,0-0.708-0.104-0.75,0.194l-0.188,1.099c-0.012,0.115-0.116,0.235-0.229,0.235H1.466c-0.084,0-0.146-0.083-0.133-0.166l0.684-4.216C2.025,9.25,2.129,9.167,2.233,9.167H3.9c0.772,0,1.446,0.29,1.446,1.157C5.346,11.513,4.617,12.215,3.425,12.215z M8.562,13.503c-0.021,0.115-0.117,0.235-0.229,0.235H7.616c-0.091,0-0.133-0.07-0.133-0.166c0-0.063,0.021-0.195,0.042-0.256c-0.262,0.289-0.729,0.492-1.104,0.492c-0.812,0-1.321-0.524-1.321-1.329c0-0.979,0.78-1.867,1.8-1.867c0.396,0,0.746,0.063,0.988,0.413c0.008-0.111,0.017-0.338,0.175-0.338h0.791c0.084,0,0.146,0.083,0.133,0.161L8.562,13.503z M10.54,14.727c-0.042,0.062-0.113,0.103-0.188,0.107h-0.8c-0.071,0-0.133-0.058-0.133-0.132c0-0.123,0.741-1.148,0.854-1.305c-0.071-0.219-0.884-2.515-0.884-2.564c0-0.07,0.062-0.144,0.133-0.144h0.78c0.104,0,0.196,0.075,0.229,0.166l0.459,1.549l1.104-1.61c0.042-0.062,0.117-0.103,0.188-0.103h0.8c0.071,0,0.133,0.074,0.133,0.144c0,0.029,0,0.05-0.02,0.07l-2.654,3.824L10.54,14.727z M15.268,12.215c-0.2,0-0.708-0.103-0.75,0.194l-0.188,1.197c-0.021,0.07-0.084,0.132-0.167,0.132h-0.854c-0.079,0-0.141-0.083-0.133-0.166l0.675-4.212c0.012-0.111,0.116-0.194,0.229-0.194h1.654c0.772,0,1.446,0.29,1.446,1.157h0.001C17.18,11.513,16.451,12.215,15.268,12.215z M20.395,13.503c-0.021,0.115-0.117,0.235-0.229,0.235h-0.721c-0.092,0-0.133-0.07-0.133-0.166c0-0.063,0.029-0.195,0.042-0.256c-0.25,0.289-0.716,0.492-1.104,0.492c-0.812,0-1.321-0.524-1.321-1.329c0-0.979,0.78-1.867,1.8-1.867c0.404,0,0.758,0.063,0.987,0.413c0.013-0.111,0.021-0.338,0.18-0.338h0.791c0.084,0,0.146,0.083,0.133,0.161L20.395,13.503z M22.667,9.324l-0.675,4.179c-0.009,0.115-0.113,0.235-0.217,0.235h-0.687c-0.084,0-0.146-0.07-0.146-0.144l0.012-0.02l0.675-4.295c0.008-0.063,0.071-0.112,0.133-0.112h0.771c0.071,0,0.133,0.062,0.133,0.144V9.324z" />
            </svg>
        );
    }
}

export default PayPalComponent;
