/* eslint-disable @typescript-eslint/naming-convention */
import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps } from 'Component/Field/Field.type';
import { ValidationInputType } from 'Util/Validator/Config';

/**
 * Returns fields for newsletter
 * @returns {[{validateOn: [string], validationRule: {isRequired: boolean, inputType: string}, type: string, attr: {name: string, placeholder: *, 'aria-label': *}}]}
 * @namespace Pwa/Component/NewsletterSubscription/NewsletterForm/Form/newsletterSubscriptionForm */
export const newsletterSubscriptionForm = (): Partial<FieldContainerProps>[] => [
    {
        type: FieldType.EMAIL,
        attr: {
            name: 'newsletterEmail',
            placeholder: __('Indirizzo email'),
            'aria-label': __('Indirizzo email'),
        },
        validateOn: ['onChange'],
        validationRule: {
            inputType: ValidationInputType.EMAIL,
            isRequired: true,
        },
    },
    {
        type: FieldType.CHECKBOX,
        attr: {
            name: 'privacyCheck',
            id: 'privacyCheck',
            'aria-label': __('Privacy check'),
            required: true, // Assicurati di avere questo attributo
        },
        validateOn: ['onChange'],
        validationRule: {
            isRequired: true,
        },
        // eslint-disable-next-line max-len
        label: __('Cliccando su Iscriviti, dichiari di accettare l’informativa privacy e acconsenti al trattamento dei dati personali per l’iscrizione alla newsletter, contenente comunicazioni tecniche, comunicazioni commerciali e promozioni relative ai prodotti e ai servizi e per fini di profilazione.'),
    },
];

export default newsletterSubscriptionForm;
