import { connect } from 'react-redux';

import {
    FooterContainer as SourceFooterContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/Footer/Footer.container';
import { RootState } from 'Util/Store/Store.type';

import { FooterContainerProps } from './Footer.type';

/** @namespace Pwa/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/Footer/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
    ...sourceMapDispatchToProps(),
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/Footer/Container */
export class FooterContainer extends SourceFooterContainer {
    static defaultProps: Partial<FooterContainerProps> = {
        copyright: '',
        isVisibleOnMobile: true,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
