export const RENDER_NEWSLETTER = 'render_newsletter';
export const RENDER_FIRST_COLUMN = 'render_first_column';
export const RENDER_FOURTH_COLUMN = 'render_fourth_column';
export const RENDER_FIFTH_COLUMN = 'render_fifth_column';

export const NEWSLETTER_COLUMN = {
    title: __('Newsletter'),
    columnActiveKey: 'newsletterActive',
    items: [
        {
            render: RENDER_NEWSLETTER,
        },
    ],
};

export const FIRST_COLUMN = {
    items: [
        {
            render: RENDER_FIRST_COLUMN,
        },
    ],
};

export const FOURTH_COLUMN = {
    title: __('Servizio clienti'),
    items: [
        {
            render: RENDER_FOURTH_COLUMN,
        },
    ],
};

export const FIFTH_COLUMN = {
    items: [
        {
            render: RENDER_FIFTH_COLUMN,
        },
    ],
};

export const COLUMN_MAP = [
    FIRST_COLUMN,
    {
        title: __('Motorparts'),
        items: [
            {
                title: __('Via Aldina, 26 - 40012'),
            },
            {
                title: __('Lippo di Calderera di Reno (BO)'),
            },
            {
                title: __('Tel. +39 051 725442'),
            },
            {
                title: __('Tel. +39 051 725449'),
            },
            {
                title: __('CF: 00871030375'),
            },
            {
                title: __('P.IVA: 00525451209'),
            },
            {
                title: __('REA: BO: 231587 Reg. Impr. Bologna'),
            },
            {
                title: __('Sito istituzionale: www.motorparts.it'),
            },
        ],
    },
    // {
    //     items: [
    //         {
    //             title: __('CF: 00871030375'),
    //         },
    //         {
    //             title: __('P.IVA: 00525451209'),
    //         },
    //         {
    //             title: __('REA: BO: 231587 Reg. Impr. Bologna'),
    //         },
    //         {
    //             title: __('Sito istituzionale: www.motorparts.it'),
    //         },
    //     ],
    // },
    {
        title: __('Informazioni'),
        items: [
            {
                href: __('/termini-e-condizioni'),
                title: __('Termini e condizioni'),
            },
            {
                href: __('/spedizioni'),
                title: __('Spedizioni'),
            },
            {
                href: __('/reso-di-prodotti'),
                title: __('Reso di prodotti'),
            },
            {
                href: __('privacy-policy'),
                title: __('Privacy policy'),
            },
            {
                href: __('cookies-policy'),
                title: __('Cookies policy'),
            },
        ],
    },
    FOURTH_COLUMN,
    FIFTH_COLUMN,
];
